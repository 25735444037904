import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Avatar, message, Tooltip } from 'antd'
import { FaBriefcase } from 'react-icons/fa'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../Components'
import SingleProject from './SingleProject'
import ProjectForm from '../../Components/Forms/ProjectForm'
import { randomColorGenerator } from '../../Content/projects'
// import accounting from '../../Controllers/accountingNumbers'
import { getAllProjects } from '../../Services/projects'
import { resetProject } from '../../Store/Actions/projects'
import { getProjectStats } from '../../Services/stats'
import { associationEnumURL } from '../../Content/timetracker'

const Projects = () => {
	const location = useLocation()
	const projectsData = useSelector(state => state.projectsData)
	const { token } = useSelector(state => state.userSession)
	const [stats, setStats] = useState(null)
	const [addDrawer, setAddDrawer] = useState(false)
	const [search, setSearch] = useState('')
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})

	const dispatch = useDispatch()

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllProjects(token, current, pageSize, sortOrder, sortField, query)

			if (!data.data) {
				dispatch(resetProject([]))
			} else {
				dispatch(resetProject(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async () => {
		try {
			const { data } = await getProjectStats(token)
			setStats(data.data)
		} catch (error) {
			setStats({
				projects: 'NA'
			})
		}
	}

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [projectsData])

	const statsObject = {
		title: 'Projects',
		total: stats?.projects,
		data: []
	}

	const columns = [
		{
			title: 'Project Name',
			key: 'name',
			dataIndex: 'name',
			sorter: true,
			render: (_, rec) => (
				<div className='flex items-center space-x-3'>
					<div className='w-10 h-10 rounded-md grid place-items-center shrink-0' style={{ backgroundColor: rec.color }}>
						<FaBriefcase color='#fff' fontSize={18} />
					</div>
					<Link to={`?id=${rec.id}`}>
						<div className='flex gap-2 items-center'>
							{rec.name}
							<HiOutlineExternalLink />
						</div>
					</Link>
				</div>
			)
		},
		{
			title: 'Project Manager',
			key: 'project_manager_name',
			dataIndex: 'project_manager_name'
		},
		{
			title: 'Association',
			key: 'association_name',
			dataIndex: 'association_name',
			render: (_, record) =>
				record.association_id ? (
					<div>
						<div className='text-xs text-bell-gray uppercase'>{record.association}</div>
						<Link
							to={`..${associationEnumURL[record.association]}?id=${record.association_id}`}
							className='line-clamp-1'
						>
							{record.association_name || `This ${record.association}`}
						</Link>
					</div>
				) : (
					<div className='border border-bell-blue w-2 ml-6' />
				)
		},
		{
			title: 'Team',
			key: 'team',
			render: (_, rec) =>
				rec.team_details?.length ? (
					<Avatar.Group>
						{rec.team_details.map(
							(a, i) =>
								a.name && (
									<Tooltip title={a.name}>
										<Avatar
											style={{ backgroundColor: randomColorGenerator(a.name, 30, 50) }}
											key={i}
											className='uppercase'
										>
											{a.name?.split(' ')[0][0]}
											{a.name?.split(' ')[1] && a.name.split(' ')[1][0]}
										</Avatar>
									</Tooltip>
								)
						)}
					</Avatar.Group>
				) : null
		}
		// {
		// 	title: 'Budget',
		// 	key: 'budget',
		// 	dataIndex: 'budget',
		// 	render: (_, rec) => (rec.budget_type === 'Time' ? `${rec.budget} h` : `USD ${accounting(rec.budget)}`)
		// }
	]

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Projects' selected='Projects' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Projects</div>
				<div>
					<DrawerComponent
						form={<ProjectForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Project'
						drawerWidth={900}
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={statsObject} nograph />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={projectsData}
					type='projects'
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleProject />
	)
}

export default Projects
